import React from 'react';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import styled from '@emotion/styled';

const StyledGatsbyImage = styled(GatsbyImage)`
  max-width: 100%;
  width: 100%;
  object-fit: cover;
`;

const LazyImage = props => {
  const { fluid, alt, ...rest } = props;

  return (
    <StyledGatsbyImage
      fluid={fluid}
      alt={alt}
      loading="eager"
      placeholderStyle={{ visibility: 'hidden' }}
      {...rest}
    />
  );
};

LazyImage.propTypes = {
  fluid: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
};

export default LazyImage;
